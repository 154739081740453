.child-level-0{    
    padding-left: 0;
}

.child-level-1{    
    padding-left: 5px;

}

.child-level-2 {    
    padding-left: 10px;
}

.child-level-3 {    
    padding-left: 15px;
}

.child-level-4 {    
    padding-left: 20px;
}

.child-level-5 {    
    padding-left: 10px;
}

.child-level-6 {    
    padding-left: 10px;
}

.child-level-7 {    
    padding-left: 10px;
}

option {
    padding: 30px
  }