.my-modal {
  height: 400px !important;
  border-radius: 30% !important;
}

.modal-dialog {
  width: 400px !important;
}

.text {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 48px;
  /* identical to box height */

  text-align: center;

  color: #000839;
}

.text-center {
  text-align: center !important;
}
