
.widget-number {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 36px !important;
    line-height: 49px !important;
    margin-bottom: 10px;
}

.widget-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
}

.card {
    border-radius: 10px !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000839 !important;
    background-color: #fff !important;
    border-bottom: 2px solid #F5B95D;
}

.circle-user {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    width: 58px;
    height: 58px;
    border-radius: 50px;
    background: rgba(18, 161, 253, 0.15);
}

.circle-orders {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    width: 58px;
    height: 58px;
    border-radius: 50px;
    background: rgba(91, 117, 248, 0.15)}

.circle-keep {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    width: 58px;
    height: 58px;
    border-radius: 50px;
    background: rgba(255, 154, 50, 0.15)}

.circle-shipping {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    width: 58px;
    height: 58px;
    border-radius: 50px;
    background :rgba(245, 43, 61, 0.15) !important;
}

.icon {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.tab-inactive {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

    color: #A0A0A0;
}

.row-circle {
    width: 36px;
    height: 36px;
    border-radius: 50%;

    background: rgba(255, 164, 27, 0.15);
}

.table-icon-1 {
    margin: 10px 10px 5px 10px;
}

.table-icon-2 {
    margin: 9px 10px 5px 9px;
}

.table-icon-3 {
    margin: 6px 10px 5px 6px;
}

.table-text {
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
}