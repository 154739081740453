.elipse {
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    left: 0px;
    top: 0px;
    margin-bottom: 20px;
    height: 150px;
    
    background: #FFF5E7;
}

.red-elipse {
    border-bottom-right-radius: 80%;
    border-bottom-left-radius: 80%;
    left: 0px;
    top: 0px;
    margin-bottom: 20px;
    height: 170px;

    background: #FFEEF0;
}

.my-modal {
    height: 400px !important;
    border-radius: 30% !important;
} 

.modal-dialog {
    width: 400px !important;
}

.text {
    font-family:'Playfair Display';
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 48px;
    /* identical to box height */

    text-align: center;

    color: #000839;
}