@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.vertical-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    text-align: center;
}

.login-container {
    background-color:#FFE9C8 !important; 
    min-height:98vh
}

.subtitle-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #A0A0A0;
    margin-bottom: 30px;
}

.title-text {
    font-family: 'Playfair Display', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
}

.ractangle {
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 116px;
    height: 116px;
    background: rgba(255, 164, 27, 0.1);
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
}

.mail-icon {
    margin-left: auto;
    margin-top: 25px;
}