.information-store {
  .box {
    border-radius: 10px;
    padding: 24px;
    background-color: white;
    .tabs {
      display: flex;
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 16px;
      margin-bottom: 36px;
      .childtab {
        margin-right: 24px;
        cursor: pointer;
        padding-bottom: 16px;
      }
      .active {
        border-bottom: 2px solid #f5b95d;
      }
    }

    //admintab
    .admin_tab {
      h5 {
        padding-bottom: 24px;
        border-bottom: 1px solid #f0f0f0;
      }
      .forms {
        padding: 16px 0;
        border-bottom: 1px solid #f0f0f0;
        p {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #000839;
        }
        .uploadfilebox {
          cursor: pointer;
          width: 105px;
          height: 105px;
          background: #ffffff;
          border: 1px dashed #e0e0e0;
          box-sizing: border-box;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          color: #a0a0a0;
          flex-direction: column;
          img {
            margin-bottom: 12px;
          }
        }
        .hexcolor {
          display: flex;
          align-items: center;
          .react-colorful {
            height: 70px;
            width: 70px;
            margin-right: 24px;
            transition: all 0.2s;
            &:hover {
              transition: all 0.2s;
              height: 120px;
              width: 120px;
              // transform: scale(1.5);
            }
          }
          .box {
            display: flex;
            align-items: center;
            background: #ffffff;
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            border-radius: 5px;
            width: 341px;
            height: 35px;
            padding: 8px 12px;
          }
        }
        .soundnotif {
          display: flex;
          flex-direction: column !important;
        }
      }
      .wrap-buttons {
        margin-top: 24px;
        button {
          color: #00a8cc;
          width: 124px;
          height: 46px;
          background: #ffffff;
          border: 1px solid #00a8cc;
          box-sizing: border-box;
          border-radius: 5px;
          &:last-of-type {
            background: #00a8cc;
            color: white;
            margin-left: 16px;
          }
        }
      }
    }
    //reseller tab
    .tab_reseller {
      h5 {
        padding-bottom: 24px;
        border-bottom: 1px solid #f0f0f0;
      }
      .forms {
        padding: 16px 0;
        border-bottom: 1px solid #f0f0f0;
        input {
          width: 400px;
          height: 35px;
          background: #ffffff;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          border-radius: 5px;
          padding: 8px 10px;
          &::placeholder {
            color: #a0a0a0;
          }
        }
      }
      .wrap-buttons {
        margin-top: 24px;
        button {
          color: #00a8cc;
          width: 124px;
          height: 46px;
          background: #ffffff;
          border: 1px solid #00a8cc;
          box-sizing: border-box;
          border-radius: 5px;
          &:last-of-type {
            background: #00a8cc;
            color: white;
            margin-left: 16px;
          }
        }
      }
    }
  }
}
