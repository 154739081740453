.user-panel {
  padding: 1rem 15px;

  display: flex;
  align-items: center;

  margin-bottom: 1rem;

  overflow: hidden;
}

.user-panel__avatar {
  flex: 0 0 45px;
}

.user-panel__avatar img {
  width: 100%;
  border-radius: 50%;
}

.user-panel__info {
  flex: 1 0 auto;

  padding: 5px 5px 5px 15px;
  opacity: 1;
  visibility: visible;

  transition: 0.2s ease-out;
}

.user-panel__info p {
  font-weight: 600;
  font-size: 16px;
  margin: 0 0 5px;

  color: #fff;
}

p.user-panel__info__online-status {
  font-size: 14px;
  margin: 0;
}

body.sider-compact .user-panel__info {
  opacity: 0;
  visibility: hidden;
}
