.detail-button {
    margin-left: 5px;
    width: 70px;
    height: 30px;
}

.approval-button {
    margin-left: 5px;
    width: 80px;
    height: 30px;
}

.detail-value {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    svg {
        height: 50px;
        width: 50px;
        margin-right: 15px;
        padding: 15px;
        background-color: rgba(245, 184, 93, 0.211);
        color: rgb(245, 184, 93);
        border-radius: 100%;
    }
    div {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  
  .detail-info-text {
      span {
        display: block;
        color: #00A8CC;
        size: 12px;
      }
  }


.addor {
    background: #ffffff;
    border: 1px solid #00a8cc;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #00a8cc;
    height: 43px;
    padding: 0 16px;
    width: fit-content;
    &:first-child {
        margin-right: 16px;
    }
}

.table-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.wrap-button {
    display: flex;
    flex-direction: row;
    button {
        margin-right: 2px;
    }
}