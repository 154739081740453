@font-face {
    font-family: merchantcopydouble;
    src: url("../fonts/merchantcopydouble.ttf");
}

@font-face {
    font-family: bahnschrift;
    src: url("../fonts/bahnschrift.ttf");
}

@font-face {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    src: url("../fonts/verdana.ttf");
}

/* @page {
    size: portrait !important;
    margin: 0;
} */

/*@media print and (width: 76mm) {*/
/*    @page {*/
/*        size: portrait !important;*/
/*    }*/

/*    body {*/
/*        margin: 1.6cm;*/
/*    }*/
/*}*/

.travel-doc-print-container {
    font-family: Verdana;
    /* width: 100%;
    max-width: 95mm;
    margin: 0 10mm; */
    font-size: 18px;
    /* line-break: normal;
    letter-spacing: 1px; */
}

/* .print-container-warehouse {
    font-family: bahnschrift, serif;
    width: 100%;
    max-width: 95mm;
    margin: 0 7mm;
    font-size: 20px;
    line-break: normal;
    letter-spacing: 1px;
} */