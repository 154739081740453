@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.vertical-center {
    margin: 0;
    position: absolute;
    width: 70%;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: left;
}

.login-container {
    background-color:#FFE9C8 !important; 
    min-height:110vh;
    height: 100% !important;
}

.btn-login {
    margin-top: 40px;
    border-radius: 5px;
    height: 46px;
}

.label-margin {
    margin-top: 10px;
    height: 46px !important;
    margin-bottom: 10px;
}

.subtitle-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #A0A0A0;
}

.title-text {
    font-family: 'Playfair Display', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
}

.notif-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
}

