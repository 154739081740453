.small-cancel-btn {
    width:90px; 
    height:27px;
    text-align: center;
    padding: 0px;
    background-color: white;
    border: white;
    color: #00A8CC
}

.center-image-gallery{
    display:flex;
     justify-content:center; /* horizontally center */
     align-items:center;    /* vertically center */
  }
  
  .center-image-gallery img{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
  }

  .file-upload-image-gallery {
    display: inline-block;
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  .img-wrap {
    position: relative;

}
.img-wrap .close {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
}

.delete-image-btn {
  position: absolute;
  top: 0;
  right: 10px;
  border-radius: 50%;
}

.file-upload-frame-gallery {
  border: 1px dashed #e0e0e0;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.5s;
  height: 100px;
}
  
.file-upload-label-gallery {
  color: #692f90;
  width: 100%;
  height: 120px;
  display: grid;
  align-items: center;
}