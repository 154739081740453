.sidebar-menu {
  white-space: nowrap;
  overflow: hidden;

  list-style: none;
  margin: 0;
  padding: 0 8px;
}

.sidebar-menu > li {
  position: relative;
  margin: 0;
}

.sidebar-menu__title {
  font-size: 14px;
  padding: 10px 25px 10px 15px;
  font-style: normal;
  font-weight: normal;

  overflow: hidden;
  text-overflow: clip;

  background-color: #ffffff;
  color: #fff;
}

.sidebar-menu > li > a,
.sidebar-menu > li > p,
.sidebar-menu > li > span,
.sidebar-menu > li > b {
  padding: 0.4rem 0.8rem;
  margin: 0;
  position: relative;
  display: block;
  border-radius: 0px;

  color: #c2c7d0;
  background-color: transparent;
  margin-bottom: 0.2rem;

  transition: 0.1s ease-out;
}

.sidebar-menu > li > a:hover,
.sidebar-menu > li > p:hover,
.sidebar-menu > li > span:hover,
.sidebar-menu > li > b:hover {
  color: #000;
  border-right: 2px solid #f5b95d;
}

.sidebar-menu > li > a.active,
.sidebar-menu > li > p.active,
.sidebar-menu > li > span.active,
.sidebar-menu > li > b.active {
  color: #494e54;
  border-right: 2px solid #f5b95d;
}

.sidebar-menu-item__icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 17px;
  vertical-align: middle;
  margin-left: 8px;
}

.sidebar-menu-item__label {
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  opacity: 1;
  visibility: visible;
  margin-left: 12px;

  font-style: normal;
  font-size: 14px;
  line-height: 19px;

  transition: 0.2 ease-out;
}

body.sider-compact .sidebar-menu-item__label {
  opacity: 0;
  visibility: hidden;
}
