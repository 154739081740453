@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.page-title {
    font-family: 'Playfair Display', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
}

.card-title {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    margin: 20px 20px 20px 20px;
}

.card-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    
    color: #A0A0A0;
}

.card {
    border-radius: 10px !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000839 !important;
    background-color: #fff !important;
    border-bottom: 2px solid #F5B95D;
}

.input-padding {
    padding-top: 8px;
    padding-bottom: 8px;
}

.button-create-customer {
    width: 50px;
    height: 43px;
    background: #00A8CC !important;
    border-radius: 5px;
}

.btn {
    width: 200px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    height: 43px;
}

.btn-cancel {
    width: 124px;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #00A8CC !important;
    border-radius: 5px;
    color: #00A8CC !important;
}

.title {
    font-family: 'Playfair Display', serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
}

.table-customer-header {
    background: rgba(245, 185, 93, 0.05);
}

.order-detail-card-circle {
    width: 36px;
    height: 36px;
    border-radius: 50%;

    background: rgba(255, 164, 27, 0.15);
}

.customer-order-detail-note {
    margin-top: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #00A8CC;
}

.redirect-contract-settings-btn {
    border: 1px solid #00A8CC;
    padding: 5px;
    border-radius: 5px;
    text-decoration: none;
}

.search-input {
    background-color: '#fff' !important; 
    color: '#F5B95D' !important;
    border-top: 1px solid #ced4da !important;
    border-left: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    border-right: 0;
}

.dot-circle {
    height: 10px;
    width: 10px;
    background-color: orange;
    border-radius: 50%;
    display: inline-block;
}

.phone-number-input {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1;
	/* The phone number input should shrink
	   to make room for the extension input */
	min-width: 0;
	display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.complain-button svg {
    cursor: pointer;
    margin-left: 2px;
    margin-right: 2px;
    color: #00A8CC;
    height: 20px;
    width: 20px;
}

.complain-button svg :hover {
    color: #0288a6;
}

.retur-table-button svg{
    cursor: pointer;
    margin-left: 2px;
    margin-right: 2px;
    height: 20px;
    width: 20px;
    color: #00A8CC;
}