@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

.App {
  height: 100%;
  /* overflow: hidden !important; */
}

.menu-title {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */

  color: #000839;
}

.small-note-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #a0a0a0;
}

.primary-btn {
  background-color: #00a8cc !important;
}

/* upload frame */
.file-upload-frame {
  border: 1px dashed #e0e0e0;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  min-height: 150px;
  transition: 0.5s;
}

.file-upload-frame:hover {
  border: 1px dashed #f37334;
}
.file-upload-label {
  color: #692f90;
  width: 100%;
  height: 150px;
  display: grid;
  align-items: center;
}

.file-upload-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.custom-badge-success {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50rem !important;
  background-color: #198754 !important;
}

.custom-badge-primary {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50rem !important;
  background-color: #0d6efd !important;
}

.custom-badge-secondary {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50rem !important;
  background-color: #6c757d !important;
}

.custom-badge-warning {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50rem !important;
  background-color: rgb(245, 185, 93) !important;
}

.custom-switch-on {
    background-color: rgb(102, 187, 106) !important;
}

.custom-badge-danger {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50rem !important;
  background-color: #dc3545;
}

.center-image {
  display: flex;
  justify-content: center; /* horizontally center */
  align-items: center; /* vertically center */
}

.center-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toggle-eye-icon {
  background-color: "#fff" !important;
  color: "#F5B95D" !important;
  border-top: 1px solid #ced4da !important;
  border-left: 0;
  border-bottom: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
}

.title {
  font-family: serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option {
  padding: 0.5rem 2.8rem !important;
}

.form-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  margin: 5px 0 15px 0;
  /* identical to box height */

  color: #000839;
}

.bootstrap-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bootstrap-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.bootstrap-input:disabled,
.bootstrap-input[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.tabs-style {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  color: black;
  margin: 0;
}

.toggle-button input{
  display: none;
}

.custom-paging-layout ul {
  margin-bottom: 0;
}

.button-icon {
  background-color: unset;
  border: unset;
}

.loading-circle-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.loading-screen-blur {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  backdrop-filter: saturate(180%) blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-screen-blur-spinner {
  color: #00a8cc;
}