.template_chatpage_product {
  // @import url("~semantic-ui-css/semantic.min.css");
  .addor {
    background: #ffffff;
    border: 1px solid #00a8cc;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #00a8cc;
    height: 43px;
    padding: 0 16px;
    width: fit-content;
    &:first-child {
      margin-right: 16px;
    }
  }
  .boxchat {
    width: 100%;
    height: 720px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: stretch;
    .list {
      width: 30%;
      //   background-color: aquamarine;
      border-right: 1px solid #f0f0f0;
      .head {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #000839;
        height: 65px;
        padding: 0 24px;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
          color: #f5b95d;
          cursor: pointer;
          width: 25px;
          height: 25px;
        }
      }
      .search {
        padding: 0 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 65px;
        border-bottom: 1px solid #f0f0f0;
        input {
          outline: none;
          // border: ;
          width: 100%;
          padding: 0 10px;
          border: none;
        }
        .svg {
          color: #e0e0e0 !important;
          height: 12px;
        }
      }
      .box {
        height: 590px;
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar {
          display: none;
        }
        ul {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          margin: 0;
          padding: 0;
          height: 500px;
          .active-chat {
            border-right: 2px solid #f5b95d;
            background: rgba(255, 164, 27, 0.05);
          }
          li {
            cursor: pointer;
            width: 100%;
            align-content: stretch;
            justify-content: stretch;
            list-style: none;
            display: flex;
            align-items: center;
            height: 71px;
            border-bottom: 1px solid #f0f0f0;
            padding: 0 24px;
            img {
              border-radius: 5px;
              width: 39px;
              height: 39px;
              margin-right: 16px;
            }
            p {
              margin-bottom: 0;
            }
            .bio {
              width: 100%;
              .name {
                font-family: Open Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                color: #000839;
                display: flex;
                justify-content: space-between;
                span {
                  font-size: 10px;
                  line-height: 14px;
                  color: #a0a0a0;
                }
              }
              .sub {
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: #a0a0a0;
                display: flex;
                justify-content: space-between;
                span {
                  font-size: 10px;
                  line-height: 14px;
                  color: #a0a0a0;
                  svg {
                    width: 3px;
                    height: 3px;
                    color: #12a1fd;
                  }
                }
              }
            }
          }
        }
      }
    }
    .chat {
      width: 70%;
      //   background-color: aliceblue;
      .head {
        height: 65px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        border-bottom: 1px solid #f0f0f0;
        svg {
          width: 23px;
          height: 23px;
          color: #a0a0a0;
          cursor: pointer;
        }
        .prof {
          align-content: stretch;
          justify-content: stretch;
          list-style: none;
          display: flex;
          align-items: center;
          img {
            border-radius: 5px;
            width: 39px;
            height: 39px;
            margin-right: 16px;
          }
          p {
            margin-bottom: 0;
            // width: 180px;
          }
          .name {
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #000839;
            display: flex;
            justify-content: space-between;
            span {
              font-size: 12px;
              color: #a0a0a0;
              margin-left: 20px;
              svg {
                width: 3px;
                height: 3px;
                color: #12a1fd;
              }
            }
          }
          .sub {
            font-size: 10px;
            line-height: 14px;
            color: #a0a0a0;
          }
        }
      }
      .boxtype {
        height: 590px + 65px;
        // background-color: aliceblue;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: stretch;
        overflow-y: auto;
        position: relative;
        // align-content: stretch;
        &::-webkit-scrollbar {
          display: none;
        }
        .chatcol {
          flex: 1;
          border-radius: 25px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          width: 100%;
          .wraptext {
            width: fit-content;
            max-width: 80%;
            .buble {
              margin-top: 16px;
              .bubleprod{
                width: 300px;
                height: 84px;
                background: #FFFFFF;
                border: 1px solid #F0F0F0;
                box-sizing: border-box;
                border-radius: 5px;
                .name{
                  font-family: Open Sans;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 19px;
                  color: #000839;
                }
                img{
                  width: 64px;
                  height: 64px;
                  border-radius: 5px;
                  object-fit: cover;
                  margin-right: 16px;
                }
                .price{
                  font-family: Open Sans;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 16px;
                  color: #A0A0A0;
                }
              }
            }
            .con {
              padding: 8px 12px;
              border-radius: 5px 0px 5px 5px;
              background: #f0f0f0;
              margin-bottom: 0;
              // width: fit-content;
            }
            .concus {
              padding: 8px 12px;
              border-radius: 0px 5px 5px 5px;
              background: rgba(245, 185, 93, 0.2);
              margin-bottom: 0;
            }
            .time {
              text-align: right;
              font-style: normal;
              font-weight: normal;
              font-size: 10px;
              line-height: 14px;
              color: #c0c0c0;
              margin: 8px 0;
            }
          }
          .broadform {
            .wrapfield {
              padding: 8px 16px;
              margin-bottom: 16px;
              min-height: 54px;
              left: 600px;
              top: 227px;
              background: #ffffff;
              border: 1px solid #e0e0e0;
              box-sizing: border-box;
              border-radius: 5px;
              display: flex;
              align-items: center;
              .selbroad {
                margin-left: 24px;
                padding-right: 0;
                border: 0 !important;
                .divider {
                  font-size: 12px !important;
                }
                .text {
                  font-family: unset !important;
                  font-style: unset !important;
                  font-weight: unset !important;
                  font-size: unset !important;
                  line-height: unset !important;
                  text-align: unset !important;
                  color: unset !important;
                  padding: 0;
                  margin-top: 16px;
                  margin-left: 0;
                }
                .icon {
                  margin: unset !important;
                  position: unset !important;
                  top: unset !important;
                  left: unset !important;
                  transform: unset !important;
                }
              }
              .broadinp {
                margin-left: 34px;
                width: 100%;
                flex: 1;
                margin: 0;
                // height: 50px;
                border: none;
                outline: none;
              }
            }
          }
        }
        .chatinput {
          // height: 70px;
          // padding: 20px;
          position: sticky;
          left: 0;
          right: 0;
          // top: 0;
          bottom: 0;
          z-index: 8;
          // height: 110px;
          // bottom: 0;
          background-color: white !important;
          .wrapinput {
            display: flex;
            align-items: center;
            height: 54px;
            background: #ffffff;
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            border-radius: 5px;
            .emoji {
              color: #f5b95d;
              width: 20px;
              height: 20px;
              margin: 0 18px;
            }
            form {
              background-color: #fff;
              display: flex;
              flex: 1;
              align-items: center;
              padding: 0;
              input {
                width: 100%;
                flex: 1;
                margin: 0;
                height: 50px;
                border: none;
                outline: none;
              }
              label {
                cursor: pointer;
                margin-bottom: 0;
                svg {
                  color: #f5b95d;
                  height: 22px;
                  width: 22px;
                  margin: 0 10px;
                }
              }
              .sendmessage {
                border: none;
                width: 38px;
                height: 38px;
                background: #f5b95d;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 8px;
                &:disabled {
                  background-color: #e0e0e0;
                }
                svg {
                  color: white;
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}


.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  a{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #A0A0A0 !important;
    text-decoration: none;
  }
  .item{
    margin: 0 3px;
    min-width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item.active{
    border: 1px solid #C0C0C0;
    border-radius: 5px;
  }
  .prev,.next{
    width: fit-content;
    font-weight: 500;
    font-size: 12px;
    height: 18px;
  }
  .prev{
    padding-right: 8px;
    margin-right: 8px;
    border-right: 1px solid #C0C0C0;;
  }
  .next{
    padding-left: 8px;
    margin-left: 8px;
    border-left: 1px solid #C0C0C0;;
  }
}